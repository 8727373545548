/* ###### Bootstrap ###### */

:root {
  --primary-bg-color: #6c5ffc;
  --primary-bg-hover: #233ac5;
  --primary-bg-border: #5161c1;
  --dark-body: #292e4a;
  --dark-border: #30314e;
  --dark-primary: #6c5ffc;
  --transparent-primary: #6c5ffc;
  --transparent-body: #584cd9;
  --transparent-theme: rgba(0, 0, 0, 0.2);
  --transparent-border: rgba(255, 255, 255, 0.1);
  --dark-primary-hover: #233ac5;
  --primary-transparentcolor: #eaedf7;
  --darkprimary-transparentcolor: #2b356e;
  --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);

  --blue: #0061f2;
  --indigo: #5800e8;
  --purple: #6900c7;
  --pink: #e30059;
  --red: #e81500;
  --orange: #f76400;
  --yellow: #f4a100;
  --green: #00ac69;
  --teal: #00ba94;
  --cyan: #00cfd5;
  --white: #fff;
  --gray: #69707a;
  --gray-dark: #363d47;
  --gray-100: #f2f6fc;
  --gray-200: #e0e5ec;
  --gray-300: #d4dae3;
  --gray-400: #c5ccd6;
  --gray-500: #a7aeb8;
  --gray-600: #69707a;
  --gray-700: #4a515b;
  --gray-800: #363d47;
  --gray-900: #212832;
  --primary: #0061f2;
  --secondary: #6900c7;
  --success: #00ac69;
  --info: #00cfd5;
  --warning: #f4a100;
  --danger: #e81500;
  --light: #f2f6fc;
  --dark: #212832;
  --black: #000;
  --white: #fff;
  --red: #e81500;
  --orange: #f76400;
  --yellow: #f4a100;
  --green: #00ac69;
  --teal: #00ba94;
  --cyan: #00cfd5;
  --blue: #0061f2;
  --indigo: #5800e8;
  --purple: #6900c7;
  --pink: #e30059;

  --bs-blue: #0061f2;
  --bs-indigo: #5800e8;
  --bs-purple: #6900c7;
  --bs-pink: #e30059;
  --bs-red: #e81500;
  --bs-orange: #f76400;
  --bs-yellow: #f4a100;
  --bs-green: #00ac69;
  --bs-teal: #00ba94;
  --bs-cyan: #00cfd5;
  --bs-white: #fff;
  --bs-gray: #69707a;
  --bs-gray-dark: #363d47;
  --bs-gray-100: #f2f6fc;
  --bs-gray-200: #e0e5ec;
  --bs-gray-300: #d4dae3;
  --bs-gray-400: #c5ccd6;
  --bs-gray-500: #a7aeb8;
  --bs-gray-600: #69707a;
  --bs-gray-700: #4a515b;
  --bs-gray-800: #363d47;
  --bs-gray-900: #212832;
  --bs-primary: #0061f2;
  --bs-secondary: #6900c7;
  --bs-success: #00ac69;
  --bs-info: #00cfd5;
  --bs-warning: #f4a100;
  --bs-danger: #e81500;
  --bs-light: #f2f6fc;
  --bs-dark: #212832;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-red: #e81500;
  --bs-orange: #f76400;
  --bs-yellow: #f4a100;
  --bs-green: #00ac69;
  --bs-teal: #00ba94;
  --bs-cyan: #00cfd5;
  --bs-blue: #0061f2;
  --bs-indigo: #5800e8;
  --bs-purple: #6900c7;
  --bs-pink: #e30059;
  --bs-red-soft: #f1e0e3;
  --bs-orange-soft: #f3e7e3;
  --bs-yellow-soft: #f2eee3;
  --bs-green-soft: #daefed;
  --bs-teal-soft: #daf0f2;
  --bs-cyan-soft: #daf2f8;
  --bs-blue-soft: #dae7fb;
  --bs-indigo-soft: #e3ddfa;
  --bs-purple-soft: #e4ddf7;
  --bs-pink-soft: #f1ddec;
  --bs-primary-soft: #dae7fb;
  --bs-secondary-soft: #e4ddf7;
  --bs-success-soft: #daefed;
  --bs-info-soft: #daf2f8;
  --bs-warning-soft: #f2eee3;
  --bs-danger-soft: #f1e0e3;
  --bs-primary-rgb: 0, 97, 242;
  --bs-secondary-rgb: 105, 0, 199;
  --bs-success-rgb: 0, 172, 105;
  --bs-info-rgb: 0, 207, 213;
  --bs-warning-rgb: 244, 161, 0;
  --bs-danger-rgb: 232, 21, 0;
  --bs-light-rgb: 242, 246, 252;
  --bs-dark-rgb: 33, 40, 50;
  --bs-black-rgb: 0, 0, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-red-rgb: 232, 21, 0;
  --bs-orange-rgb: 247, 100, 0;
  --bs-yellow-rgb: 244, 161, 0;
  --bs-green-rgb: 0, 172, 105;
  --bs-teal-rgb: 0, 186, 148;
  --bs-cyan-rgb: 0, 207, 213;
  --bs-blue-rgb: 0, 97, 242;
  --bs-indigo-rgb: 88, 0, 232;
  --bs-purple-rgb: 105, 0, 199;
  --bs-pink-rgb: 227, 0, 89;
  --bs-red-soft-rgb: 241, 224, 227;
  --bs-orange-soft-rgb: 243, 231, 227;
  --bs-yellow-soft-rgb: 242, 238, 227;
  --bs-green-soft-rgb: 218, 239, 237;
  --bs-teal-soft-rgb: 218, 240, 242;
  --bs-cyan-soft-rgb: 218, 242, 248;
  --bs-blue-soft-rgb: 218, 231, 251;
  --bs-indigo-soft-rgb: 227, 221, 250;
  --bs-purple-soft-rgb: 228, 221, 247;
  --bs-pink-soft-rgb: 241, 221, 236;
  --bs-primary-soft-rgb: 218, 231, 251;
  --bs-secondary-soft-rgb: 228, 221, 247;
  --bs-success-soft-rgb: 218, 239, 237;
  --bs-info-soft-rgb: 218, 242, 248;
  --bs-warning-soft-rgb: 242, 238, 227;
  --bs-danger-soft-rgb: 241, 224, 227;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 105, 112, 122;
  --bs-body-bg-rgb: 242, 246, 252;

  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $default-color;
  text-align: left;
  background-color: $background;
}

.number-font {
  font-weight: 600 !important;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr {
  &[title],
  &[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  &:hover {
    text-decoration: underline;
  }
  &:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      color: inherit;
      text-decoration: none;
      outline: 0;
    }
  }
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

svg:not(:root) {
  overflow: hidden;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #505662;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input {
  &[type="radio"],
  &[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }
  &[type="date"],
  &[type="time"],
  &[type="datetime-local"],
  &[type="month"] {
    -webkit-appearance: listbox;
  }
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.171875rem;
  font-weight: 300;
  line-height: 1.4;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid $border;
  opacity: 0.25;
  background-color: transparent;
}

small,
.small {
  font-size: 87.5%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  li {
    border-bottom: 1px solid $border;
  }
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

code,
kbd,
pre,
samp {
  font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  font-size: 85%;
  color: inherit;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 85%;
  color: $white;
  background-color: $dark;
  border-radius: 3px;
  kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }
}

pre {
  display: block;
  font-size: 85%;
  color: #212529;
  code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

@media (max-width: 576px) {
  .container {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.users img {
  max-width: none !important;
}

@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;
    &::before,
    &::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body,
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid $black;
  }
  .table,
  .text-wrap table {
    border-collapse: collapse !important;
  }
  .table td,
  .text-wrap table td,
  .table th,
  .text-wrap table th {
    background-color: $white !important;
  }
  .table-bordered th,
  .text-wrap table th,
  .table-bordered td,
  .text-wrap table td {
    border: 1px solid $border !important;
  }
}

html {
  font-size: 16px;
}

body {
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  * {
    &::-webkit-scrollbar {
      width: 2px;
      height: 6px;
      transition: 0.3s background;
    }
    &::-webkit-scrollbar-thumb {
      background: #f6f6fb;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: #f6f6fb;
    }
  }
}

@media print {
  body {
    background: none;
  }
}

a {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip: ink;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: inherit;
}

strong,
b {
  font-weight: 500;
}

code {
  background: #f6f6fb;
  border: 1px solid $border;
  border-radius: 3px;
  padding: 0px 3px;
  color: $pink;
}

pre code {
  padding: 0;
  border-radius: 0;
  border: none;
  background: none;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

pre {
  color: $dark;
  padding: 1rem;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f6fb;
  border-radius: 3px;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  text-shadow: 0 1px white;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

.section-nav {
  background-color: #f8f9fa;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  border: 1px solid $border;
  border-radius: 3px;
  list-style: none;
  &:before {
    content: "Table of contents:";
    display: block;
    font-weight: 600;
  }
}

@media print {
  .container {
    max-width: none;
  }
}

.row-cards > {
  .col,
  [class*="col-"] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.row-deck > {
  .col,
  [class*="col-"] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .col .card,
  [class*="col-"] .card {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}

.col-text {
  max-width: 48rem;
}

.col-login {
  max-width: 24rem;
}
